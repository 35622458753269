/* eslint-disable camelcase */

import * as yup from 'yup';
import { translate } from 'utils/i18nUtils';
import { URL_REGEX, URL_WITH_SCHEMA_REGEX } from 'constants/regex';

const menuLinkValidationSchema = yup.object().shape({
  links: yup.array(
    yup.object().shape({
      url: yup
        .string()
        .nullable()
        .when('name', {
          is: (val) => !!val,
          then: yup
            .string()
            .nullable()
            .required(translate('tenantFormValidationError.links_url.required'))
            .matches(
              URL_WITH_SCHEMA_REGEX,
              translate('tenantFormValidationError.links_url.invalid')
            )
        }),
      name: yup
        .string()
        .nullable()
        .strict(true)
        .trim(translate('tenantFormValidationError.link_name.noWhiteSpace'))
        .test(
          'required',
          translate('tenantFormValidationError.link_name.required'),
          (value, form) => {
            return form.parent.url && !value ? false : true;
          }
        )
    })
  )
});

const buttonColorSchemaRules = yup.object().shape({
  variant: yup.string().required(),
  color: yup.string().when('variant', {
    is: 'plain',
    then: yup.string().required(),
    otherwise: yup.string()
  }),
  gradient: yup.mixed().when('variant', {
    is: 'gradient',
    then: yup.array(
      yup
        .string()
        .required(
          translate(
            'tenantFormValidationError.primary_button_bg_color.required'
          )
        )
    ),
    otherwise: yup.mixed()
  })
});

const applicationOptionValidateSchema = yup.object().shape({
  allowed_options: yup
    .array()
    .min(
      1,
      translate('tenantFormValidationError.applicationApplyMethods.required')
    )
});
const assetValidateSchema = yup.object().shape({
  sections: yup.object().shape({
    footer: menuLinkValidationSchema,
    header: menuLinkValidationSchema
  })
});

const styleInputValidateSchema = yup.object().shape({
  primary_font_family: yup.string().required(),
  secondary_font_color: yup.string().required(),
  primary_font_color: yup.string().required(),
  primary_button_bg_color: buttonColorSchemaRules,
  primary_button_hover_bg_color: buttonColorSchemaRules,
  highlight_color: yup.string().required(),
  image_overlay: yup.object().shape({
    gradient: yup.array(yup.string().required())
  }),
  button_radius: yup.string().required()
});

const TenantFormValidationSchema = yup.object().shape({
  customer_name: yup
    .string()
    .required(translate('tenantFormValidationError.customer_name.required')),
  bm_url: yup
    .string()
    .required(translate('tenantFormValidationError.bm_url.required'))
    .matches(URL_REGEX, translate('tenantFormValidationError.bm_url.url')),
  tj_url: yup
    .string()
    .required(translate('tenantFormValidationError.tj_url.required'))
    .matches(URL_REGEX, translate('tenantFormValidationError.tj_url.url')),
  username: yup
    .string()
    .required(translate('tenantFormValidationError.username.required')),
  password: yup.string().when('mode', {
    is: 'create',
    then: yup
      .string()
      .required(translate('tenantFormValidationError.password.required'))
  }),
  tenant_configuration: yup.object().shape({
    styleInput: styleInputValidateSchema,
    ui: yup.object().shape({
      application_options: applicationOptionValidateSchema,
      assets: assetValidateSchema
    })
  })
});

const TenantFormBranchUIValidateSchema = yup.object().shape({
  tenant_configuration: yup.object().shape({
    branches: yup.array().of(
      yup.object().shape({
        styleInput: styleInputValidateSchema,
        ui: yup.object().shape({
          assets: assetValidateSchema
        })
      })
    )
  })
});

const TenantFormBranchApplicationOptionValidateSchema = yup.object().shape({
  tenant_configuration: yup.object().shape({
    branches: yup.array().of(
      yup.object().shape({
        ui: yup.object().shape({
          application_options: applicationOptionValidateSchema
        })
      })
    )
  })
});

export default TenantFormValidationSchema;

export {
  TenantFormBranchUIValidateSchema,
  TenantFormBranchApplicationOptionValidateSchema
};
