export const cssTranspiler = (target) => {
  /* eslint-disable */

  const cssVar = new Proxy({...target, hover_color: '#73c0ff', focus_color: '#2072e6'}, handler);

  const str = `body { font-family: '${cssVar['primary_font_family']}'; } .primary-font-color { color: ${cssVar['primary_font_color']};
} .secondary-font-color { color: ${cssVar['secondary_font_color']};
} .highlight-color { color: ${cssVar['highlight_color']};
}
.tj-form-password-input-group button.tj-password-eye-btn:hover { color: ${cssVar['highlight_color']};
}
.highlight-on-hover:hover { color: ${cssVar['highlight_color']};
} .app-confirmation-img > div { background: ${cssVar['image_overlay']};
} .bg-gradient { background: ${cssVar['bg_gradient']};
} .tj-button-secondary { background: ${cssVar['secondary_button_bg_color']}; border-radius: ${cssVar['button_radius']};
}
.tj-button-primary { background: ${cssVar['primary_button_bg_color']}; border-radius: ${cssVar['button_radius']};
}
.tj-button-primary:hover:not(:disabled),
.tj-button-primary:focus,
.tj-button-primary:focus-visible { background: ${cssVar['primary_button_hover_bg_color']}; outline: 0;
}
.tj-button-secondary:hover:not(:disabled),
.tj-button-secondary:focus,
.tj-button-secondary:focus-visible { background: ${cssVar['secondary_button_hover_bg_color']}; outline: 0;
} .header-nav-bar { background-color: ${cssVar['header_bg_color']};
} .footer { background-color: ${cssVar['footer_bg_color']};
} input.form-control { color: ${cssVar['primary_font_color']};
} .tj-form-label { color: ${cssVar['secondary_font_color']};
} .title-label { color: ${cssVar['secondary_font_color']};
} .tj-form-text-area-group textarea { color: ${cssVar['primary_font_color']};
} .tj-checkbox label { color: ${cssVar['primary_font_color']};
} .tj-radio-checkbox-label { color: ${cssVar['primary_font_color']};
} .form-check-label,
.custom-control-label { color: ${cssVar['primary_font_color']};
} .form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label { color: ${cssVar['primary_font_color']};
} .tj-drop-down-item-wrapper .item-button { color: ${cssVar['primary_font_color']};
} .form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label { color: ${cssVar['primary_font_color']};
}
.dynamic-checkbox-wrapper .message-feedback:hover,
.dynamic-radio-wrapper .message-feedback:hover { color: ${cssVar['primary_font_color']};
} .tj-checkbox input[type='checkbox']:checked,
.active input[type='checkbox']:checked:hover { background: ${cssVar['checkbox_radio_bg']}; border-color: ${cssVar['image_overlay_first_color']};
}
.item .radio-button:checked + .circle-button::after,
.item .radio-button:checked + .circle-button:hover:after { background: ${cssVar['checkbox_radio_bg']};
}
.item .radio-button:checked + .circle-button,
.item .radio-button:checked + .circle-button:hover { border-color: ${cssVar['image_overlay_first_color']};
} .tj-drop-down-container .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover,
.tj-drop-down-container .Calendar__day.-selected,
.tj-drop-down-container .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.tj-drop-down-container .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText { background: ${cssVar['highlight_color']};
} .form-group .input-group:hover .message-feedback,
.message-feedback:hover,
.tj-file-container-application-form .card-message-container:hover .message-feedback { color: ${cssVar['hover_color']};
}
.input-group:hover input,
.input-group:hover textarea,
.input-group:hover .tj-selector,
.tj-file-container-application-form .card-message-container:hover .card-body { border-color: ${cssVar['hover_color']};
} .input-group:hover .tj-raw-selector-wrapper.is-open .tj-selector,
.input-group:hover input:focus,
.input-group:hover textarea:focus { border-color: ${cssVar['focus_color']};
} .spinner { background: ${cssVar['conic_gradient_bg_color']};
} .spinner::after { background-color: ${cssVar['image_overlay_first_color']};
} .layout-container { background-color: ${cssVar['application_bg_color']};
} .application-bg-color { background-color: ${cssVar['application_bg_color']};
} .application-progress-bar-text p { color: ${cssVar['secondary_font_color']};
} .toggle-switch-inner:before { background: ${cssVar['primary_button_bg_color']};
}
.toggle-switch-inner:hover:not(:disabled):before { background: ${cssVar['primary_button_hover_bg_color']};
}
.toggle-switch-label:hover > .toggle-switch-inner:before { background: ${cssVar['primary_button_hover_bg_color']};
} .tj-step-item-container:first-child .tj-step-number-wrapper span.tj-step-line.left .opacity-mask { background-image: ${cssVar['timeline_left_opacity_mask']};
} .tj-step-item-container:last-child .tj-step-number-wrapper span.tj-step-line.right .opacity-mask { background-image: ${cssVar['timeline_right_opacity_mask']};
} .job-ad-container .content .image-not-active { background: ${cssVar['bg_gradient']};
} .job-ad-container .content .image-active { background: ${cssVar['image_overlay']};
}
.avatar-inner-circle { background: ${cssVar['bg_gradient']};
}
.avatar-title { color: ${cssVar['highlight_color']};
}
.avatar-outer-circle { background: ${cssVar['bg_gradient']};
}
.search-not-found-title-main,
.search-not-found-subtitle { color: ${cssVar['secondary_font_color']};
} .tj-job-card .job-short-info-row .link .is-bold,
.tj-job-card .job-short-info-row a .is-bold { color: ${cssVar['highlight_color']};
} .tj-button-primary-font-color { color: ${cssVar['primary_button_font_color']};
}
.tj-button-secondary-font-color { color: ${cssVar['secondary_button_font_color']};
}
`;
  return str
    .replace(/[a-z-]+:(\s+)?'?(\s+)?'?(\s+)?;/gi, '')
    .replace(
      /[a-z-_:^="><~\[\].#0-9\s\*,\+\|\$''\(\)]+(\s+)?{(\s+)?}(\s+)?/gi,
      ''
    );
  /* eslint-enable */
};

const handler = {
  get: function (target, prop) {
    return target[prop] ? target[prop] : '';
  }
};
