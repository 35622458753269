import React, { Suspense, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { injectCss } from 'utils';
import { getJSONConfiguration } from 'api/httpCommonApi';

const CustomerCenterConfigurationContext = React.createContext();

const initialState = {
  styleInput: {},
  // Key to store the Tenant Configuration
  ui: {},
  // Key to store the css style string
  styles: '',
  // Key to store the request resolve status
  isFetching: true
};

const CustomerCenterConfigurationContextProvider = ({
  children,
  endPoint = '/cc-configuration.json'
}) => {
  // Reducer to hold the Application global state
  const [state, dispatch] = useReducer(
    CustomerCenterConfigurationContextReducer,
    initialState
  );

  const updateFetchStatus = (fetchStatus) =>
    dispatch({
      type: 'UPDATE_CC_CONFIG_FETCHING_STATUS',
      payload: { fetchStatus }
    });

  /**
   * Hook, active once component did mount.
   */
  useEffect(function () {
    const handleDidMount = async () => {
      try {
        // Fetching the Configuration
        const response = await getJSONConfiguration(endPoint);

        const {
          styleInput,
          ui: { styles, ...rest },
          branches
        } = get(response, 'data.cc_configuration');
        dispatch({
          type: 'UPDATE_CC_CONFIG_DATA',
          payload: { styleInput, ui: rest, styles, isFetching: false, branches }
        });
      } catch (error) {
        updateFetchStatus(false);
        console.error(error, 'Error : Fetch and Assign Configuration');
      }
    };

    handleDidMount();
  }, []);

  useEffect(() => {
    if (!state.isFetching) {
      injectCss(state.styles, 'cc_styles');
    }

    return () => {
      const styleTag = document.querySelector('head style#cc_styles');

      if (styleTag) {
        styleTag.remove();
      }
    };
  }, [state.isFetching]);

  return (
    <CustomerCenterConfigurationContext.Provider value={{ state, dispatch }}>
      <Suspense fallback="loading">
        {!state.isFetching ? children : null}
      </Suspense>
    </CustomerCenterConfigurationContext.Provider>
  );
};

function CustomerCenterConfigurationContextReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_CC_CONFIG_DATA':
      return {
        ...state,
        ...action.payload
      };
    case 'UPDATE_CC_CONFIG_FETCHING_STATUS':
      return {
        ...state,
        isFetching: action.payload.fetchStatus
      };
    default:
      return state;
  }
}

CustomerCenterConfigurationContextProvider.propTypes = {
  children: PropTypes.element,
  endPoint: PropTypes.string
};

export {
  CustomerCenterConfigurationContext,
  CustomerCenterConfigurationContextProvider
};
