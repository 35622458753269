import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CustomerCenterConfigurationContextProvider } from 'context/CustomerCenterConfigurationProvider';
import { AuthProvider } from './context';
import Header from 'components/header';
import LoginPage from 'pages/LoginPage';
import TenantCreatePage from 'pages/tenant/TenantCreatePage';
import TenantEditPage from 'pages/tenant/TenantEditPage';
import TenantListPage from 'pages/tenant/TenantListPage';
import './App.scss';
import { TjNotificationProvider } from '@talent-journey/tj-fe-commons';
import TodoPage from 'pages/TodoPage';

const App = () => {
  return (
    <Router>
      <TjNotificationProvider>
        <CustomerCenterConfigurationContextProvider>
          <div className="App">
            <div className="outer">
              <div className="inner">
                <Switch>
                  <Route exact path="/" component={LoginPage} />
                  <Route exact path="/login" component={LoginPage} />
                  <Route path="/todo" key="todo" exact component={TodoPage} />
                  {/* Tenant Routes  */}
                  <Route
                    path={['/tenant', '/tenant/:id/edit', '/tenant/create']}
                    render={({ location }) => {
                      return (
                        <AuthProvider location={location}>
                          <Header />
                          <Switch>
                            <Route
                              path="/tenant/create"
                              key="/tenant/create"
                              exact={true}
                              component={TenantCreatePage}
                            />
                            <Route
                              path="/tenant/:id/edit"
                              key="/tenant/:id/edit"
                              exact={true}
                              component={TenantEditPage}
                            />
                            <Route
                              path="/tenant/:id/edit/:branchId/:section(ui|application-option)"
                              key="/tenant/:id/edit/:branchId/:section"
                              exact={true}
                              component={TenantEditPage}
                            />
                            <Route
                              path="/tenant"
                              key="/tenant"
                              exact={true}
                              component={TenantListPage}
                            />
                          </Switch>
                        </AuthProvider>
                      );
                    }}
                  />
                  {/* End Tenant Routes */}
                </Switch>
              </div>
            </div>
          </div>
        </CustomerCenterConfigurationContextProvider>
      </TjNotificationProvider>
    </Router>
  );
};

export default App;
