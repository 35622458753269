/* eslint-disable camelcase */
import { post, get, patch, put } from 'config/axiosConfig';

const { REACT_APP_TJ_BE_BASE_URL: baseUrl } = process.env;

export const addCustomer = (data) => post(`${baseUrl}/tenant`, data);

export const editCustomer = (id, data) =>
  patch(`${baseUrl}/tenant/${id}`, data);

export const getCustomer = (tenantId) => get(`${baseUrl}/tenant/${tenantId}`);

export const getCustomers = () => get(`${baseUrl}/tenant`);

export const getTenantConfiguration = (tenantId) =>
  get(`${baseUrl}/tenant/${tenantId}/configuration`);

export const getTenantBranchConfiguration = (tenantId, branchId) =>
  get(`${baseUrl}/${tenantId}/${branchId}/configuration`);

export const updateTenantConfiguration = (tenantId, tenant_configuration) =>
  patch(`${baseUrl}/tenant/${tenantId}/configuration`, {
    tenant_configuration
  });

export const updateTenantBranchConfiguration = (
  tenantId,
  branchId,
  ui_configuration
) =>
  put(`${baseUrl}/${tenantId}/${branchId}/configuration`, {
    ui_configuration
  });

export const clearTenantJobCache = (tenantId) =>
  post(`${baseUrl}/bm/${tenantId}/position/clearcache`);

export const getBranches = (tenantId, config) =>
  get(`${baseUrl}/bm/${tenantId}/branches`, config);

export const getBranchesStatus = (tenantId, branchId) =>
  get(`${baseUrl}/${tenantId}/configuration/status`, {
    params: { branch_id: branchId }
  });

export const updateBranchesStatus = (tenantId, branchId, data) =>
  put(`${baseUrl}/${tenantId}/${branchId}/configuration/status`, data);
