import axios from 'axios';
import { loginHTTPConfig } from '../httpCommonApi';

const { REACT_APP_TJ_BE_BASE_URL } = process.env;

export const login = (data) => {
  const config = loginHTTPConfig(data.username, data.password);

  return axios.post(`${REACT_APP_TJ_BE_BASE_URL}/login`, {}, config);
};
