/* eslint-disable camelcase */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getBranches,
  getBranchesStatus,
  updateBranchesStatus
} from 'api/customer/customerApi';
import { useForm } from 'react-final-form';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { BasicToggleSwitch, TjButton } from '@talent-journey/tj-fe-commons';
import { useState } from 'react';
import { useCallback } from 'react';
import { isArray, range } from 'lodash';
import { set } from 'lodash';

const BranchConfiguration = ({ tenantId, branchId }) => {
  const [branches, setBranches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize] = useState(25);
  const [isBusy, setBusy] = useState(true);
  const form = useForm();
  const formState = form.getState();

  useEffect(() => {
    const fetchBranchList = async () => {
      if (!tenantId || branchId) {
        return;
      }

      setBusy(true);
      try {
        const { data } = await getBranches(tenantId, {
          params: {
            'page-number': currentPage,
            'page-size': pageSize
          }
        });

        const branchIds = data?.branches?.map((b) => b.id).join(',');
        const branchStatusObj = {};

        if (branchIds) {
          const { data: branchStatus } = await getBranchesStatus(
            tenantId,
            branchIds
          );

          if (isArray(branchStatus)) {
            branchStatus.forEach((b) => {
              branchStatusObj[`_${b.branch_id}`] = b.is_active;
            });
          }
        }

        setBranches(
          data?.branches.map((b) => ({
            ...b,
            is_active: branchStatusObj[`_${b.id}`]
          }))
        );
        setTotalCount(parseInt(data?.meta?.count));
      } catch (error) {
        console.log('Error', error);
      }

      setBusy(false);
    };

    fetchBranchList();
  }, [tenantId, branchId, currentPage, pageSize]);

  const totalPage =
    totalCount > pageSize ? Math.ceil(totalCount / pageSize) : 0;

  return (
    <div data-testid={`branch-length-${totalCount}`}>
      {!branchId && totalCount > 1 ? (
        <>
          <div>
            <h4 className="section-title">Branches:</h4>
          </div>
          <Row>
            <Col>
              <p>
                We identified there are different branches configured for [
                {formState.values.customer_name}]. Would you like all the
                branches to get the general configuration, or do you want to
                specify a different configuration for each branch? Please
                Select:
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="tab-menu">
                {range(totalPage).map((index) => {
                  const page = index + 1;
                  return (
                    <li
                      role="button"
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={page === currentPage ? 'active' : ''}>
                      <span>Page {page}</span>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
          <Card className="tenant-card branch-card">
            <Card.Body>
              {isBusy ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" role="status" />
                </div>
              ) : null}
              {branches?.map((branch) => {
                return (
                  <Row
                    data-testid={`branch-row-${branch.id}`}
                    className="flex-column"
                    key={branch.id}>
                    <Col className="d-flex align-items-center">
                      <p>
                        <strong>{branch.name}</strong>
                      </p>
                    </Col>
                    <Col>
                      <Row className="flex-column">
                        <Col>
                          <div className="branch-config-option-row">
                            <Row>
                              <Col md={4} className="d-flex align-items-center">
                                UI Configuration
                              </Col>
                              <Col>
                                <Row className="justify-content-between">
                                  <Col className="d-flex align-items-center">
                                    <div className="mr-3">General</div>
                                    <div>
                                      <BranchStatusUpdate
                                        name="ui_configuration"
                                        isActive={branch.is_active}
                                        branchId={branch.id}
                                        tenantId={tenantId}
                                        setBranches={setBranches}
                                      />
                                    </div>
                                    <div>Branch Specific</div>
                                  </Col>
                                  <Col className="d-flex align-items-center justify-content-end">
                                    <TjButton
                                      data-testid={`go-ui-btn-${branch.id}`}
                                      type="button"
                                      disabled={
                                        !branch?.is_active?.ui_configuration
                                      }
                                      onClick={() => {
                                        window.open(
                                          `/tenant/${tenantId}/edit/${
                                            branch.id
                                          }/ui?branch=${encodeURI(
                                            branch.name
                                          )}`,
                                          '_blank'
                                        );
                                      }}>
                                      UI Configuration
                                    </TjButton>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col>
                          <div className="branch-config-option-row">
                            <Row>
                              <Col md={4} className="d-flex align-items-center">
                                Application
                              </Col>
                              <Col>
                                <Row className="justify-content-between">
                                  <Col className="d-flex align-items-center">
                                    <div className="mr-3">General</div>
                                    <div>
                                      <BranchStatusUpdate
                                        name="application_option"
                                        isActive={branch.is_active}
                                        branchId={branch.id}
                                        tenantId={tenantId}
                                        setBranches={setBranches}
                                      />
                                    </div>
                                    <div>Branch Specific</div>
                                  </Col>
                                  <Col className="d-flex align-items-center justify-content-end">
                                    <TjButton
                                      type="button"
                                      data-testid={`go-application-option-btn-${branch.id}`}
                                      disabled={
                                        !branch?.is_active?.application_option
                                      }
                                      onClick={() =>
                                        window.open(
                                          `/tenant/${tenantId}/edit/${
                                            branch.id
                                          }/application-option?branch=${encodeURI(
                                            branch.name
                                          )}`,
                                          '_blank'
                                        )
                                      }>
                                      Application Option
                                    </TjButton>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </Card.Body>
          </Card>
        </>
      ) : null}
    </div>
  );
};

const BranchStatusUpdate = ({
  name = 'ui_configuration',
  branchId,
  tenantId,
  isActive,
  setBranches
}) => {
  const checked = isActive && isActive[name] ? true : false;

  const [isBusy, setBusy] = useState(false);

  const changeBranchStatus = useCallback(
    (status) => {
      setBranches((branches) => {
        return branches.map((b) => {
          const branch = { ...b };
          if (b.id === branchId) {
            set(branch, ['is_active', name], status);
          }

          return branch;
        });
      });
    },
    [name, setBranches]
  );

  const handleChangeStatus = useCallback(async () => {
    const newStatus = !checked;
    setBusy(true);
    changeBranchStatus(newStatus);

    try {
      await updateBranchesStatus(tenantId, branchId, {
        is_active: { ...isActive, [name]: newStatus }
      });
    } catch (error) {
      console.log('Error to change status', error);
      changeBranchStatus(!newStatus);
    }

    setBusy(false);
  }, [checked, tenantId, branchId, isActive, name, changeBranchStatus]);

  return (
    <BasicToggleSwitch
      id={`${name}_${branchId}`}
      name={`${name}_${branchId}`}
      dataTestid={`${name}_${branchId}`}
      checked={checked}
      disabled={isBusy}
      onChange={handleChangeStatus}
    />
  );
};

BranchStatusUpdate.propTypes = {
  name: PropTypes.string,
  tenantId: PropTypes.string,
  branchId: PropTypes.string,
  setBranches: PropTypes.func,
  isActive: PropTypes.shape({
    application_option: PropTypes.bool,
    ui_configuration: PropTypes.bool
  })
};

BranchConfiguration.propTypes = {
  tenantId: PropTypes.string,
  branchId: PropTypes.string
};

export { BranchStatusUpdate };

export default BranchConfiguration;
