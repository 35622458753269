import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const OuterLayerTrigger = ({ onClickOutside, children, ...props }) => {
  const rootElement = useRef(null);

  const handleOutsideClick = useCallback((e) => {
    if (!(rootElement.current && rootElement.current.contains(e.target))) {
      onClickOutside();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div ref={rootElement} {...props}>
      {children}
    </div>
  );
};

OuterLayerTrigger.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

export default OuterLayerTrigger;
