import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import { TjTextInput, Label, TjTooltip } from '@talent-journey/tj-fe-commons';

export const SocialField = () => {
  const prefix = 'tenant_configuration.third_party_credentials';
  const linkedinTooltip =
    'Once LinkedIn App is created go to My apps > Your App name > Auth. Here developer will get a Client ID and Client Secret';
  const xingTooltip =
    'Once Xing App is created go to Dashboards > My Apps. Here developer will get a Consumer Key and Consumer Secret.';
  return (
    <>
      <h4 className="section-title">
        LinkedIn & Xing Apply configuration (optional)
      </h4>
      <Card className="tenant-card">
        <Card.Header className="card-header-custom">
          LinkedIn
          <TjTooltip message={linkedinTooltip} place="top" />
        </Card.Header>
        <Card.Body className="mb-0">
          <SocialFieldWrapper
            label="Client Id"
            name={`${prefix}.linkedIn.clientId`}
            data-testid="linkedInClientId"
            placeholder="E.g: 123ab4cde5gh67"
          />
          <SocialFieldWrapper
            label="Client Secret"
            name={`${prefix}.linkedIn.clientSecret`}
            data-testid="linkedInClientSecret"
            placeholder="E.g: 02ab456cde7gh89m"
          />
        </Card.Body>
        <Card.Header className="card-header-custom">
          Xing
          <TjTooltip message={xingTooltip} place="top" />
        </Card.Header>
        <Card.Body>
          <SocialFieldWrapper
            label="Consumer Key"
            name={`${prefix}.xing.consumerKey`}
            data-testid="xingConsumerKey"
            placeholder="E.g: 123456abc7de8fghij9k"
          />
          <SocialFieldWrapper
            label="Consumer Secret"
            name={`${prefix}.xing.consumerSecret`}
            data-testid="xingConsumerSecret"
            placeholder="E.g: 12345678abc90d123ef4567g890h1i2345j6kl89"
          />
        </Card.Body>
      </Card>
    </>
  );
};

const SocialFieldWrapper = ({ name, label, htmlFor, ...restProps }) => {
  return (
    <Row className="form-group">
      <Label
        label={label}
        htmlFor={htmlFor}
        className="col-sm-3 col-form-label"
      />
      <Col sm={9}>
        <TjTextInput name={name} {...restProps} />
      </Col>
    </Row>
  );
};

SocialFieldWrapper.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  htmlFor: PropTypes.string
};
