import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from 'api/login/loginApi';
import { getPayload } from 'utils/common.util';
import { handleApiErrors } from 'utils/errorHandler';
import { setCookie, getCookie } from '../../utils/common.util';
import Login from './Login';
import { TjNotificationContext } from '@talent-journey/tj-fe-commons';

const LoginContainer = () => {
  const history = useHistory();

  const { showNotification } = useContext(TjNotificationContext);

  useEffect(() => {
    const accessToken = getCookie('accessToken');
    if (accessToken) {
      history.push('/tenant');
    }
  }, []);

  const handleSubmit = async (inputValues) => {
    const { email, password } = inputValues;

    const data = { username: email, password };
    try {
      const response = await login(data);
      const token = response.data.access_token;
      const payload = getPayload(token);
      const exTime = payload.exp * 1000;

      setCookie('accessToken', token, exTime);
      history.push('/tenant');
    } catch (error) {
      const { getMessage } = handleApiErrors(error.response, 'login');
      showNotification(getMessage(), {
        type: 'error'
      });
    }
  };

  return <Login handleSubmit={handleSubmit} />;
};

export default LoginContainer;
