/* eslint-disable  camelcase */

import { toBase64 } from 'utils/common.util';

import {
  editCustomer,
  addCustomer,
  updateTenantConfiguration,
  updateTenantBranchConfiguration
} from 'api/customer/customerApi';
import { StatusCodes } from 'http-status-codes';
import { get, set } from 'lodash';
import getCssString from './getCssString';
import { apiMessageTransform } from 'utils/i18nUtils';

export async function convertTenantFileIntoBase64(obj, key) {
  try {
    const localId = get(obj, `${key}._localId`);
    // That means, user did not make any change in file.
    if (!localId) {
      return;
    }

    const base64Data = await toBase64(get(obj, `${key}.file`));

    set(obj, `${key}._id`, localId);
    set(obj, `${key}.file`, base64Data);
    set(obj, `${key}._localId`, undefined);
  } catch (error) {
    set(obj, `${key}`, '');
    console.log('Error in file conversion', error);
  }
}
/**
 * handle, customer form submission
 * @param {object} values  - form values object
 * @returns {function}
 */

const saveTenantData =
  ({ showNotification, history, translate, tenantId, branchId }) =>
  async ({
    customer_name,
    bm_url,
    username,
    password,
    tj_url,
    mode,
    tenant_configuration
  }) => {
    const tenantData = {
      customer_name,
      bm_url,
      username,
      password: mode === 'edit' && !password ? undefined : password,
      tj_url
    };

    let _tenantId = tenantId;

    if (!tenantId) {
      tenant_configuration.isCacheEnable = true;
      tenant_configuration.cacheTimeout = 30;
    }

    try {
      if (!tenantId) {
        const response = await addCustomer(tenantData);
        _tenantId = get(response, 'data.data.tenant_id');
      } else if (tenantId && !branchId) {
        await editCustomer(tenantId, tenantData);
      }

      tenant_configuration.ui.styles = getCssString(
        tenant_configuration.styleInput
      );

      await convertTenantFileIntoBase64(
        tenant_configuration,
        'ui.assets.global.tenant_logo'
      );
      await convertTenantFileIntoBase64(
        tenant_configuration,
        'ui.assets.global.favicon_logo'
      );

      if (branchId) {
        await updateTenantBranchConfiguration(
          _tenantId,
          branchId,
          tenant_configuration
        );
      } else {
        await updateTenantConfiguration(_tenantId, tenant_configuration);
      }

      showNotification('Saved Successfully', {
        type: 'success'
      });

      branchId ? window.close() : history.push('/tenant');
    } catch (error) {
      const status = get(error, 'response.status');
      let message = null;

      if (status === StatusCodes.BAD_REQUEST) {
        message = apiMessageTransform(get(error, 'response.data.message'));
      } else {
        message = translate('tenantFormServerValidationError.unknownError');
      }

      showNotification(message, {
        type: 'error'
      });
    }
  };

export default saveTenantData;
