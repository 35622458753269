import PropTypes from 'prop-types';
import TenantFormContainer from 'components/tenant/form/TenantFormContainer';

const TenantEditPage = ({
  match: {
    params: { id, branchId, section }
  }
}) => (
  <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div className="container">
      <TenantFormContainer
        section={section}
        branchId={branchId}
        tenantId={id}
      />
    </div>
  </main>
);

TenantEditPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      branchId: PropTypes.string,
      section: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  })
};

export default TenantEditPage;
