import axios from 'axios';
import axiosConfig from 'config/axiosConfig';
import { getCookie } from '../utils/common.util';

const { REACT_APP_TJ_BE_BASE_URL, REACT_APP_BM_API_BASE_URL } = process.env;

export const getHeader = () => {
  const token = getCookie('accessToken');

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      Accept: 'application/vnd.api+json',
      'Content-type': 'application/vnd.api+json'
    };
  }
};

const getLinkedInHeader = () => {
  const token = getCookie('accessToken');

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json'
    };
  } else {
    return {};
  }
};

export const loginHTTPConfig = (username, password) => ({
  headers: {
    Authorization: 'Basic ' + btoa(username + ':' + password),
    'Content-type': 'application/x-www-form-urlencoded',
    Accept: 'application/json'
  }
});

export const htmApi = axios.create({
  baseURL: REACT_APP_TJ_BE_BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

export const privateHttpRequest = async () => {
  const header = await getHeader();

  return axios.create({
    baseURL: REACT_APP_BM_API_BASE_URL.toString() + '/api/v1',
    headers: header
  });
};

export const linkedinHttpRequest = axios.create({
  baseURL: 'https://api.linkedin.com/v2',
  headers: getLinkedInHeader()
});

export const getJSONConfiguration = async (endpoint) => {
  return axiosConfig.get(endpoint);
};
