import TenantFormContainer from 'components/tenant/form/TenantFormContainer';

const TenantCreatePage = () => (
  <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
    <div className="container">
      <TenantFormContainer />
    </div>
  </main>
);

export default TenantCreatePage;
