import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
  TjTextInput,
  TjRadioButton,
  TjSelect,
  TjColorPicker,
  TjFileSelectorMobileVersion,
  TjFileUpload
} from '@talent-journey/tj-fe-commons';
import { useFormState, useForm } from 'react-final-form';
import { get } from 'lodash';
export const GeneralUiConfigField = () => {
  const prefix = 'tenant_configuration.styleInput';
  const assetsPrefix = 'tenant_configuration.ui.assets';
  const sectionPrefix = 'tenant_configuration.ui.assets.sections';

  return (
    <>
      <div>
        <h4 className="section-title">General UI Configuration</h4>
      </div>
      <div className="card tenant-card">
        <div className="card sub-header">
          <div className="card-header card-header-custom">Font</div>
          <div className="card-body">
            <blockquote className="mb-0">
              <div className="form-group row">
                <Label
                  htmlFor="mainfontcolor"
                  className="col-sm-3 col-form-label"
                  label="Font Family:"
                />
                <div className="col-sm-3">
                  <TjSelect
                    name={`${prefix}.primary_font_family`}
                    placeholder="Font Family"
                    options={[
                      'Open Sans',
                      'Arial',
                      'Montserrat',
                      'Roboto',
                      'ff-meta-web-pro',
                      'Helvetica Now'
                    ]}
                  />
                </div>
              </div>
              <FontColorInput prefix={prefix} label="Primary Font Color:" />
              <FontColorInput
                prefix={prefix}
                label="Secondary Font Color:"
                name="secondary_font_color"
              />
            </blockquote>
          </div>
        </div>
        <div className="card mt-3 sub-header">
          <div className="card-header card-header-custom">Button</div>
          <div className="card-body">
            <blockquote className="mb-0">
              <PrimaryButtonStyleInput
                prefix={prefix}
                label="Primary Button Color:"
                name="primary_button_bg_color"
              />
              <PrimaryButtonStyleInput
                prefix={prefix}
                label="Primary Button Hover Color:"
                name="primary_button_hover_bg_color"
              />
              <FontColorInput
                prefix={prefix}
                label="Primary Button Font Color:"
                name="primary_button_font_color"
              />
              <SecondaryButtonStyleInput
                prefix={prefix}
                name="secondary_button_bg_color"
                label="Secondary Button Color:"
              />
              <SecondaryButtonStyleInput
                prefix={prefix}
                name="secondary_button_hover_bg_color"
                label="Secondary Button Hover Color:"
              />
              <FontColorInput
                prefix={prefix}
                label="Secondary Button Font Color:"
                name="secondary_button_font_color"
              />
              <ButtonRadius
                prefix={prefix}
                name="button_radius"
                label="Button Shape:"
              />
            </blockquote>
          </div>
        </div>
        <div className="card mt-3 sub-header">
          <div className="card-header card-header-custom">Header</div>
          <div className="card-body">
            <blockquote className="mb-0">
              <div className="form-group row">
                <LabelColorPicker
                  label="Background Color:"
                  colorPickerName={`${prefix}.header_bg_color`}
                />
              </div>
              <div className="form-group row">
                <HeaderFooterLink
                  prefix={sectionPrefix}
                  label="Link 1:"
                  index={0}
                />
              </div>
              <div className="form-group row">
                <HeaderFooterLink
                  prefix={sectionPrefix}
                  label="Link 2:"
                  index={1}
                />
              </div>
              <div className="form-group row">
                <HeaderFooterLink
                  prefix={sectionPrefix}
                  label="Link 3:"
                  index={2}
                />
              </div>
            </blockquote>
          </div>
        </div>
        <div className="card mt-3 sub-header">
          <div className="card-header card-header-custom">Footer</div>
          <div className="card-body">
            <blockquote className="mb-0">
              <div className="form-group row">
                <LabelColorPicker
                  label="Background Color:"
                  colorPickerName={`${prefix}.footer_bg_color`}
                />
              </div>
              <div className="form-group row">
                <HeaderFooterLink
                  prefix={sectionPrefix}
                  section="footer"
                  label="Link 1:"
                  index={0}
                />
              </div>
              <div className="form-group row">
                <HeaderFooterLink
                  prefix={sectionPrefix}
                  section="footer"
                  label="Link 2:"
                  index={1}
                />
              </div>
              <div className="form-group row">
                <HeaderFooterLink
                  prefix={sectionPrefix}
                  section="footer"
                  label="Link 3:"
                  index={2}
                />
              </div>
            </blockquote>
          </div>
        </div>
        <div className="card mt-3 sub-header">
          <div className="card-header card-header-custom">
            Images and details
          </div>
          <div className="card-body">
            <blockquote className="mb-0">
              <div className="form-group row">
                <Label
                  className="col-sm-3 col-form-label"
                  label=" Gradient of Image:"
                />
                <div className="col-sm-3">
                  <TjColorPicker
                    prefix={prefix}
                    name={`${prefix}.image_overlay.gradient.0`}
                  />
                </div>
                <div className="col-sm-3">
                  <TjColorPicker
                    prefix={prefix}
                    name={`${prefix}.image_overlay.gradient.1`}
                  />
                </div>
              </div>
              <div className="form-group row">
                <Label
                  className="col-sm-3 col-form-label"
                  label="Highlight color:"
                />
                <div className="col-sm-6">
                  <TjColorPicker
                    prefix={prefix}
                    name={`${prefix}.highlight_color`}
                  />
                </div>
              </div>
            </blockquote>
          </div>
        </div>
        <div className="card mt-3 sub-header" id="fileUploadSection">
          <div className="card-header card-header-custom">
            Logo, Favicon and Title
          </div>
          <div className="card-body">
            <blockquote className="mb-0">
              <div className="form-group row">
                <Label
                  htmlFor="mainfontcolor"
                  className="col-sm-3 col-form-label"
                  label="Upload Company Logo:"
                />
                <div className="col-sm-3 logoUpload">
                  <TjFileUpload
                    name={`${assetsPrefix}.global.tenant_logo`}
                    label="Upload your file"
                    multiple={false}
                    shouldAutoUpload={false}
                    noWhiteBg={true}
                    component={TjFileSelectorMobileVersion}
                    hint="Supports jpeg, jpg or png of maximum 5MB"
                    rules={{
                      maxFileSize: 5,
                      acceptedFiles: ['image/jpeg', 'image/png']
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <Label
                  htmlFor="mainfontcolor"
                  className="col-sm-3 col-form-label"
                  label="Upload Company Favicon:"
                />
                <div className="col-sm-3 logoUpload">
                  <TjFileUpload
                    name={`${assetsPrefix}.global.favicon_logo`}
                    label="Upload your file"
                    multiple={false}
                    noWhiteBg={true}
                    shouldAutoUpload={false}
                    component={TjFileSelectorMobileVersion}
                    hint="Supports  jpeg, jpg, png or ico of maximum 1MB"
                    rules={{
                      maxFileSize: 1,
                      acceptedFiles: ['image/jpeg', 'image/png', 'image/x-icon']
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <Label
                  htmlFor="mainfontcolor"
                  className="col-sm-3 col-form-label"
                  label="Talent Journey Title:"
                />
                <div className="col-sm-3">
                  <TjTextInput
                    name={`${assetsPrefix}.global.application_title`}
                  />
                </div>
              </div>
              <div className="form-group row">
                <LabelColorPicker
                  label="Background Color:"
                  colorPickerName={`${prefix}.application_bg_color`}
                />
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </>
  );
};

const LabelColorPicker = ({ label, colorPickerName }) => {
  return (
    <>
      <Label
        htmlFor="mainfontcolor"
        className="col-sm-3 col-form-label"
        label={label}
      />
      <div className="col-sm-3">
        <TjColorPicker name={colorPickerName} />
      </div>
    </>
  );
};

const LinkUrlInputs = ({ label, linkName, url }) => {
  const form = useForm();
  function handleInputChange(event) {
    if (event.target.value == '') {
      form.change(url, 'https://');
    }
  }

  return (
    <>
      <Label
        htmlFor="mainfontcolor"
        className="col-sm-3 col-form-label"
        label={label}
      />
      <div className="col-sm-4">
        <TjTextInput
          placeholder="Type the name of the link"
          data-testid={linkName}
          name={linkName}
        />
      </div>
      <div className="col-sm-4">
        <TjTextInput
          onClick={handleInputChange}
          data-testid={url}
          placeholder="Type the URL"
          name={url}
        />
      </div>
    </>
  );
};

const HeaderFooterLink = ({
  prefix = 'tenant_configuration.ui.assets.sections',
  label,
  index,
  section = 'header'
}) => {
  return (
    <LinkUrlInputs
      label={label}
      linkName={`${prefix}.${section}.links.${index}.name`}
      url={`${prefix}.${section}.links.${index}.url`}
    />
  );
};

const FontColorInput = ({
  prefix = 'tenant_configuration.styleInput',
  label,
  name = 'primary_font_color'
}) => {
  return (
    <div className="form-group row">
      <LabelColorPicker label={label} colorPickerName={`${prefix}.${name}`} />
    </div>
  );
};

const SecondaryButtonStyleInput = ({
  prefix = 'tenant_configuration.styleInput',
  name,
  label
}) => {
  return (
    <div className="form-group row">
      <LabelColorPicker label={label} colorPickerName={`${prefix}.${name}`} />
    </div>
  );
};

const PrimaryButtonStyleInput = ({
  prefix = 'tenant_configuration.styleInput',
  name,
  label
}) => {
  const formState = useFormState();
  const primaryBtnColorVariant = get(
    formState.values,
    `${prefix}.${name}.variant`
  );

  return (
    <>
      <div className="form-group row">
        <Label
          htmlFor="mainfontcolor"
          className="col-sm-3 col-form-label"
          label={label}
        />
        <div className="form-check form-check-inline">
          <TjRadioButton
            name={`${prefix}.${name}.variant`}
            id={`_${name}_plain`}
            value="plain"
            label="Plain Color"
            data-testid={`${name}.variant_plain`}
          />
        </div>
        <div className="col-sm-3">
          <TjColorPicker
            disabled={primaryBtnColorVariant === 'gradient'}
            name={`${prefix}.${name}.color`}
            dataTestid={`${prefix}.${name}.color`}
          />
        </div>
      </div>
      <div className="form-group row">
        <Label
          htmlFor="mainfontcolor"
          className="col-sm-3 col-form-label"
          label=""
        />
        <div className="form-check form-check-inline">
          <TjRadioButton
            name={`${prefix}.${name}.variant`}
            id={`_${name}_gradient`}
            value="gradient"
            label="Gradient Color"
            data-testid={`${name}.variant_gradient`}
          />
        </div>
        <div className="col-sm-3">
          <TjColorPicker
            disabled={primaryBtnColorVariant === 'plain'}
            name={`${prefix}.${name}.gradient.0`}
          />
        </div>

        <div className="col-sm-3">
          <TjColorPicker
            disabled={primaryBtnColorVariant === 'plain'}
            name={`${prefix}.${name}.gradient.1`}
          />
        </div>
      </div>
    </>
  );
};

const ButtonRadius = ({
  prefix = 'tenant_configuration.styleInput',
  name,
  label
}) => {
  return (
    <>
      <div className="form-group row">
        <Label
          htmlFor="mainfontcolor"
          className="col-sm-3 col-form-label"
          label={label}
        />
        <div className="form-check form-check-inline py-2">
          <TjRadioButton
            name={`${prefix}.${name}`}
            id={`_${name}_rounded`}
            value="40px"
            label="Rounded"
            data-testid={`${name}.variant_rounded`}
          />
        </div>
      </div>
      <div className="form-group row">
        <Label
          htmlFor="mainfontcolor"
          className="col-sm-3 col-form-label"
          label=""
        />
        <div className="form-check form-check-inline">
          <TjRadioButton
            name={`${prefix}.${name}`}
            id={`_${name}_squared`}
            value="0px"
            label="Squared"
            data-testid={`${name}.variant_squared`}
          />
        </div>
      </div>
    </>
  );
};

LabelColorPicker.propTypes = {
  label: PropTypes.string,
  colorPickerName: PropTypes.string.isRequired
};

LinkUrlInputs.propTypes = {
  label: PropTypes.string,
  linkName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

HeaderFooterLink.propTypes = {
  label: PropTypes.string,
  index: PropTypes.number.isRequired,
  section: PropTypes.string,
  prefix: PropTypes.string
};

FontColorInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  prefix: PropTypes.string
};

PrimaryButtonStyleInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string
};

SecondaryButtonStyleInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string
};

ButtonRadius.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string
};
