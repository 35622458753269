import React, { useEffect, useState } from 'react';
import { getCookie, getPayload } from '../utils/common.util';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthStateContext = React.createContext();

const AuthProvider = ({ children, location }) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  useEffect(() => {
    const accessToken = getCookie('accessToken');
    if (!accessToken) {
      history.push('/login');
      return null;
    }

    const payload = getPayload(accessToken);
    setUser({
      sub: payload.sub,
      username: payload.username
    });
  }, [location.pathname]);

  return !user ? (
    <div />
  ) : (
    <AuthStateContext.Provider value={user}>
      {children}
    </AuthStateContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default AuthProvider;
