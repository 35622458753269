import { get } from 'lodash';
import en from 'assets/i18n/errorMessages_en_GB.json';
import { apiMessageTransform as coreApiMessageTransform } from '@talent-journey/tj-fe-commons';

export const languages = {
  en
};

/**
 *
 * @param {string} key -> trans key depth
 * @param {string} lang -> language code
 * @returns
 */
export const translate = (key, lang = 'en') => get(languages[lang], key, key);

/**
 * To transform the api error message.
 *
 * @param {string} lang -> language code
 * @returns string
 */
export const apiMessageTransform = coreApiMessageTransform.bind(
  undefined,
  translate
);
