import React, { useState, useEffect, useContext } from 'react';
import TenantList from './TenantList';
import { getCustomers } from 'api/customer/customerApi';
import { handleApiErrors } from 'utils/errorHandler';
import { TjNotificationContext } from '@talent-journey/tj-fe-commons';

const TenantListContainer = () => {
  const [tenantsList, setTenantsList] = useState([]);
  const { showNotification } = useContext(TjNotificationContext);

  useEffect(() => {
    const fetchTenantList = async () => {
      try {
        const { data } = await getCustomers();

        setTenantsList(data.tenantList);
      } catch (error) {
        const { getMessage } = handleApiErrors(error.response, 'getCustomers');
        showNotification(getMessage(), { error: 'error' });
      }
    };

    fetchTenantList();
  }, []);

  return <TenantList tenantsList={tenantsList} />;
};

export default TenantListContainer;
