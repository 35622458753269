import { TjTextInput } from '@talent-journey/tj-fe-commons';
import React, { useCallback } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TODO_SAGA } from 'store/types/todoTypes';

const TodoPage = () => {
  const dispatch = useDispatch();
  const { todos, error, busy } = useSelector((state) => state.todos);
  const handleSubmit = (values) => {
    dispatch({ type: ADD_TODO_SAGA, payload: { todo: values.todo } });
  };

  const handelEnterPress = useCallback(
    (submitFnc, resetFnc, event) => {
      if (!busy && event.key === 'Enter') {
        submitFnc();
        resetFnc();
      }
    },
    [busy]
  );

  const _todos = [...todos].reverse();

  return (
    <Container>
      <h2 className="mt-4">TODO DEMO</h2>
      <Card>
        <Card.Body>
          <Form
            disabled={busy}
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <>
                  <TjTextInput
                    data-testid="todo"
                    name="todo"
                    onKeyDown={(event) => {
                      handelEnterPress(
                        formProps.handleSubmit,
                        formProps.form.reset,
                        event
                      );
                    }}
                  />
                  {busy ? 'saving...' : null}
                  {error ? <p>Error: {error}</p> : null}
                  <ul>
                    {_todos.map((todo) => (
                      <li key={todo}>{todo}</li>
                    ))}
                  </ul>
                </>
              );
            }}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TodoPage;
