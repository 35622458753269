/* eslint-disable camelcase */
import { useContext, useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import {
  getCustomer,
  getTenantBranchConfiguration,
  getTenantConfiguration
} from 'api/customer/customerApi';
import { handleApiErrors } from 'utils/errorHandler';
import { translate } from 'utils/i18nUtils';
import TenantForm from './TenantForm';
import { CustomerCenterConfigurationContext } from 'context/CustomerCenterConfigurationProvider';
import TenantAction from './TenantAction';
import { TjNotificationContext } from '@talent-journey/tj-fe-commons';
import saveTenantData from './saveTenantData';

const TenantFormContainer = ({ tenantId, section, branchId }) => {
  const { showNotification } = useContext(TjNotificationContext);
  const history = useHistory();

  const branchName = new URLSearchParams(history.location.search).get('branch');

  const {
    state: { styleInput, ui, branches }
  } = useContext(CustomerCenterConfigurationContext);

  /**
   * Form Initial values
   */

  const [initialValues, setInitialValues] = useState({
    tenant_configuration: {
      styleInput,
      ui,
      branches
    },
    mode: tenantId ? 'edit' : 'create'
  });

  const goToTenantsList = () => history.push('/tenant');

  /**
   * handle, customer form submission
   * @param {object} values  - form values object
   * @returns object | void
   */

  const handleSubmit = useMemo(() => {
    return saveTenantData({
      history,
      showNotification,
      translate,
      tenantId,
      branchId,
      section
    });
  }, [tenantId]);

  useEffect(() => {
    const fetchCustomer = async () => {
      if (tenantId) {
        try {
          const response = await getCustomer(tenantId);
          const tenantConfigResponse = await getTenantConfiguration(tenantId);
          let tenantConfiguration = get(
            tenantConfigResponse,
            'data.data.tenant_configuration',
            {}
          );

          if (branchId && section) {
            const {
              data: { data: tenantBranchConfig }
            } = await getTenantBranchConfiguration(tenantId, branchId);
            const isSectionActive = get(
              tenantBranchConfig,
              `is_active.${
                section === 'ui' ? 'ui_configuration' : 'application_option'
              }`
            );

            if (isSectionActive && tenantBranchConfig.ui_configuration) {
              tenantConfiguration = tenantBranchConfig.ui_configuration;
              delete tenantConfiguration?.allowed_mime_type;
              delete tenantConfiguration?.customer_features;
              delete tenantConfiguration?.third_party_credentials;
              delete tenantConfiguration?.third_party_default_credentials;
            }
          }

          /* eslint-disable */
          const { bm_url, customer_name, username, bm_id, tj_url } =
            response.data;
          setInitialValues({
            bm_url,
            bm_id,
            customer_name,
            username,
            tj_url,
            tenant_configuration: tenantConfiguration
          });
          /* eslint-enable */
        } catch (error) {
          const { getMessage } = handleApiErrors(error.response, 'getCustomer');
          showNotification(getMessage(), {
            type: 'error'
          });

          goToTenantsList();
        }
      }
    };

    fetchCustomer();
  }, [tenantId, branchId, section]);

  const editTitle = branchName
    ? `[${initialValues.customer_name}] ${branchName}`
    : initialValues.customer_name;

  return (
    <div className="container">
      <div className="row tenantheader">
        <h2>{tenantId ? editTitle : translate('tenant.createTenantTitle')}</h2>
        {tenantId ? <TenantAction tenantId={tenantId} /> : null}
      </div>
      <TenantForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        tenantId={tenantId}
        branchId={branchId}
        section={section}
      />
    </div>
  );
};

TenantFormContainer.propTypes = {
  tenantId: PropTypes.string,
  section: PropTypes.string,
  branchId: PropTypes.string
};

export default TenantFormContainer;
