import jwt from 'jsonwebtoken';

/**
 * Check if key value exist in json array
 *
 * @param arr
 * @param key
 * @param value
 * @returns {boolean}
 */
export const isKeyValueExist = (arr, key, value) => {
  return arr.some((item) => item[key] === value);
};

/**
 * It will compare two array and see if they have some common item or not
 *
 * @param arr1
 * @param arr2
 * @returns {*}
 */
export const findCommonElements = (arr1, arr2) => {
  return arr1.some((item) => arr2.includes(item));
};

export const getMatchItem = (arr, key, value) => {
  return arr.find((items) => items[key] === value);
};

/**
 *  TO get payload of jwt token
 * @param token
 * @returns {string}
 */
export const getPayload = (token) => {
  const decodedToken = jwt.decode(token, { complete: true });
  return decodedToken['payload'];
};

/**
 * To check expiry of jwt token
 * @param token
 * @returns {boolean}
 */
export const isTokenExpire = (token) => {
  const payload = getPayload(token);
  let dateNow = Math.floor(Date.now() / 1000);

  if (payload.exp < dateNow) {
    return true;
  }

  return false;
};

/**
 * To set cookie
 *
 * @param cookieName
 * @param cookieValue
 * @param path
 * @param timestamp
 */
export function setCookie(
  cookieName,
  cookieValue,
  timestamp = null,
  path = '/'
) {
  let cookieString = cookieName + '=' + cookieValue;
  if (timestamp !== null) {
    let d = new Date();
    d.setTime(timestamp);
    const expires = 'expires=' + d.toUTCString();
    cookieString = `${cookieString};${expires}`;
  }

  cookieString = `${cookieString};path=${path}`;
  document.cookie = cookieString;
}

/**
 * To get cookie
 *
 * @param cname
 * @returns {string}
 */
export function getCookie(cookieName) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  let res = '';
  ca.forEach((c) => {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      res = c.substring(name.length, c.length);
      return;
    }
  });

  return res;
}

/**
 * To delete cookie
 *
 * @param cookieName
 * @param path
 */
export function deleteCookie(cookieName, path = '/') {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
}

/**
 * Function to return base64 formated content of the passed file
 *
 * @param {*} file
 * @returns
 */
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => reject(error);
  });

export const sleep = (timeout) =>
  new Promise((resolve) => setTimeout(resolve, timeout));
