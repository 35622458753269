import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
  TjTextInput,
  TjPasswordInput,
  Label,
  TjToggleSwitch
} from '@talent-journey/tj-fe-commons';
import PropTypes from 'prop-types';

export const BasicField = () => {
  return (
    <>
      <div>
        <h4 className="section-title">Customer Details</h4>
      </div>
      <Card className="tenant-card">
        <Card.Body>
          <BasicFieldWrapper
            name="customer_name"
            label="Customer Name"
            data-testid="customer_name"
            required={true}
          />
          <BasicFieldWrapper
            name="bm_url"
            data-testid="bm_url"
            label="Customer BM url"
            required={true}
          />
          <BasicFieldWrapper
            name="username"
            data-testid="username"
            label="API user"
            required={true}
          />
          <BasicFieldWrapper
            data-testid="raw-password-input"
            name="password"
            label="Password"
            type="password"
            autoComplete="new-password"
            required={true}
          />
          <BasicFieldWrapper
            data-testid="tj_url"
            name="tj_url"
            label="CJ Url"
            required={true}
          />
          <Row className="row-justify">
            <Col className="col-sm-3">
              <Label
                htmlFor="jobBoardandJobAd"
                className="form-label tj-form-label "
                label="Job Board & Job Ad"
              />
            </Col>

            <Col className="col-sm-6 d-flex align-items-center">
              <div className="mr-3">Disabled</div>
              <div>
                <TjToggleSwitch
                  name="tenant_configuration.customer_features.job_board"
                  id="tenant_configuration.customer_features.job_board"
                />
              </div>
              <div>Enabled</div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const BasicFieldWrapper = ({
  name,
  required,
  label,
  type = 'text',
  ...restProps
}) => {
  return (
    <div className=" row row-justify">
      <div className="col-sm-3 ">
        <Label label={label} required={required} />
      </div>
      <div className="col-sm-6">
        {type === 'text' ? (
          <TjTextInput name={name} {...restProps} />
        ) : (
          <TjPasswordInput name={name} {...restProps} />
        )}
      </div>
    </div>
  );
};

BasicFieldWrapper.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password'])
};
