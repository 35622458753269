import {
  ADD_TODO_ITEM,
  TODO_REQUEST_STATUS,
  TODO_REQUEST_ERROR
} from 'store/types/todoTypes';

const initialState = {
  busy: false,
  todos: [],
  error: null
};

const todoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TODO_ITEM:
      state.todos.push(action.payload.todo);
      return { ...state, busy: false };

    case TODO_REQUEST_STATUS:
      return { ...state, busy: action.payload.busy, error: null };

    case TODO_REQUEST_ERROR:
      return { ...state, busy: false, error: action.payload.error };

    default:
      return state;
  }
};

export default todoReducer;
