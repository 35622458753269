import axios from 'axios';
import { getCookie } from '../utils/common.util';

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((config) => {
  const token = getCookie('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export const get = axiosInstance.get;
export const post = axiosInstance.post;
export const put = axiosInstance.put;
export const remove = axiosInstance.delete;
export const patch = axiosInstance.patch;

export default axiosInstance;
