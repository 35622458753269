import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';

import * as yup from 'yup';
import {
  TjButton,
  TjPasswordInput,
  TjTextInput
} from '@talent-journey/tj-fe-commons';
import { validateFormValues } from 'utils/formUtil';
import loginLogo from 'assets/images/login.jpg';
import listLogo from 'assets/images/list.svg';

const focusOnError = createDecorator();

const Login = ({ handleSubmit }) => {
  return (
    <Form
      decorators={[focusOnError]}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize={true}
      validate={validateFormValues(validationSchema)}
      render={(formProps) => {
        return (
          <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
            <div className="container">
              <div className="card login-card">
                <div className="row no-gutters">
                  <div className="col-md-5">
                    <img
                      src={loginLogo}
                      alt="login"
                      className="login-card-img"
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="card-body">
                      <div className="brand-wrapper">
                        <img src={listLogo} alt="logo" className="logo" />
                      </div>
                      <p className="login-card-description">
                        Sign into your account
                      </p>
                      <form
                        className="form-signin"
                        onSubmit={formProps.handleSubmit}>
                        <div className="form-group">
                          <TjTextInput
                            name="email"
                            id="email"
                            data-testid="emailTest"
                          />
                        </div>
                        <div className="form-group mb-4">
                          <TjPasswordInput
                            name="password"
                            id="password"
                            placeholder="***********"
                            dataTestid="passwordTest"
                          />
                        </div>
                        <TjButton id="login" type="submit">
                          Login
                        </TjButton>
                      </form>
                      <a href="#!" className="forgot-password-link">
                        Forgot password?
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        );
      }}
    />
  );
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Please enter valid email').required('required'),
  password: yup.string().required('required')
});

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default Login;
