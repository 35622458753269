import { isArray, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { Field, useField } from 'react-final-form';
import { Container, Draggable } from '@edorivai/react-smooth-dnd';
import PropTypes from 'prop-types';

export const ExchangeOptionField = ({ name, options }) => {
  const [data, setData] = useState([...options]);

  const {
    input,
    meta: { touched, error, submitError, initial }
  } = useField(name, { multiple: true });

  const {
    input: { value: isChanged }
  } = useField(`${name}_changed`);

  useEffect(
    function () {
      if (isArray(initial)) {
        const filteredOption = options.filter(
          (d) => !initial.some((iv) => isEqual(iv, d))
        );
        setData(filteredOption);
      } else {
        setData([...options]);
      }
    },
    [
      options.map((v) => (typeof v === 'object' ? v.value : v)).join(','),
      initial
    ]
  );

  useEffect(
    function () {
      if (isArray(input.value)) {
        const filteredOption = options.filter(
          (d) => !input.value.some((iv) => isEqual(iv, d))
        );
        setData(filteredOption);
      }
    },
    [isChanged]
  );

  /**
   * handle, when item is dropped in options container.
   * @param {*} param0
   */
  const handleDropOption = ({ removedIndex, addedIndex, payload }) => {
    optionShuffleFnc(data, setData, { removedIndex, addedIndex, payload });
  };

  /**
   * Handle, When item is dropped in value container.
   * @param {*} param0
   */
  const handleDropValue = ({ removedIndex, addedIndex, payload }) => {
    optionShuffleFnc(input.value, input.onChange, {
      removedIndex,
      addedIndex,
      payload
    });
  };

  return (
    <FormGroup data-testid={`${name}_field_wrapper`}>
      <input name={name} className="exchange-input" />
      <Field name={`${name}_changed`} component={() => null} />
      <InputGroup
        as={Row}
        className={`exchange-option-dnd-row ${
          (error || submitError) && touched ? 'invalid-row' : ''
        }`}>
        <Col className="value-container" data-testid="value-container">
          <Container
            groupName={`${name}_container`}
            onDrop={handleDropValue}
            getChildPayload={(i) => input.value[i]}
            behaviour="move">
            {isArray(input.value) &&
              input.value.map((opt, index) => (
                <Draggable key={typeof opt === 'object' ? opt.value : opt}>
                  <Row className="position-relative">
                    <Col>
                      <div className="dnd-btn">
                        <h5>
                          <span className="order-number">{index + 1}</span>
                          <span className="text">
                            {typeof opt === 'object' ? opt.label : opt}
                          </span>
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </Draggable>
              ))}
          </Container>
        </Col>
        <Col data-testid="options-container">
          <Container
            groupName={`${name}_container`}
            behaviour="move"
            onDrop={handleDropOption}
            getChildPayload={(i) => data[i]}>
            {data.map((opt) => (
              <Draggable key={typeof opt === 'object' ? opt.value : opt}>
                <div className="dnd-btn">
                  <h5>{typeof opt === 'object' ? opt.label : opt}</h5>
                </div>
              </Draggable>
            ))}
          </Container>
        </Col>
        <Col sm={12}>
          {(error || submitError) && touched ? (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error || submitError}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      </InputGroup>
    </FormGroup>
  );
};

ExchangeOptionField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      }),
      PropTypes.number,
      PropTypes.string
    ])
  )
};

/**
 * To manipulate the order of items
 */
export const optionShuffleFnc = (
  values,
  fnc,
  { removedIndex, addedIndex, payload }
) => {
  const valueCopy = [...values];
  if (typeof removedIndex === 'number') {
    valueCopy.splice(removedIndex, 1);
  }

  if (typeof addedIndex === 'number') {
    valueCopy.splice(addedIndex, 0, payload);
  }

  fnc(valueCopy);
};
