import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PencilSquare, Plus } from 'react-bootstrap-icons';
import { TjTag } from '@talent-journey/tj-fe-commons';

const tag = { label: 'Invalid BM credential', status: 'unsuccessful' };

const TenantList = ({ tenantsList }) => {
  const listItems = tenantsList.map((tenantData) => (
    <div data-testid="tenantList" key={`_${tenantData.tenant_id}`}>
      <Card className="mt-2 panel panel-primary">
        <Card.Body className="p-0">
          <table className="table tenantTableStyle mb-0">
            <tbody>
              <tr className="tableRowStyle">
                <td>
                  <span>{tenantData.customer_name}</span>
                </td>
                <td>
                  <span>
                    {tenantData.is_bm_token_valid === true ? (
                      tenantData.bm_url
                    ) : (
                      <TjTag label={tag.label} status={tag.status} />
                    )}
                  </span>
                </td>
                <td>
                  <span>{tenantData.tj_url}</span>
                </td>
                <td>
                  <span>{tenantData.last_updated_by}</span>
                </td>
                <td>
                  <span>
                    <Link to={`/tenant/${tenantData.tenant_id}/edit`}>
                      <PencilSquare color="#ED1E79" size={30} />
                    </Link>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </div>
  ));

  return (
    <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
      <div className="container">
        <div className="row tenantheader">
          <h2 data-testid="title">Candidate Journey Customer Center</h2>
        </div>
        <div className="card tenant-card mb-5">
          <div className="row no-gutters panel-body">
            <button className="text-on-pannel text-primary fill">
              <Link to="/tenant/create">
                <Plus color="#ffffff" size={30} />
              </Link>
            </button>
            <div className="container m-auto">
              <div>
                <table id="table" className="table">
                  <thead>
                    <tr>
                      <th scope="col">Customer</th>
                      <th scope="col">BM url</th>
                      <th scope="col">CJ url</th>
                      <th scope="col">Last Modified By</th>
                      <th scope="col">Edit</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <Row className="tenantListStyle">
                <Col className="tenantTable">{listItems}</Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

/* eslint-disable camelcase */
TenantList.propTypes = {
  tenantsList: PropTypes.arrayOf(
    PropTypes.shape({
      bm_id: PropTypes.string,
      bm_url: PropTypes.string,
      created_at: PropTypes.string,
      created_by: PropTypes.string,
      customer_name: PropTypes.string,
      modified_at: PropTypes.string,
      username: PropTypes.string
    })
  ).isRequired
};

export default TenantList;
