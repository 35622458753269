import React from 'react';
import { TjButton } from '@talent-journey/tj-fe-commons';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { translate } from 'utils/i18nUtils';

const CancelButton = ({
  isDirty,
  submitting,
  redirectPath,
  confirmHeaderTextKey,
  confirmCancelButtonTextKey,
  confirmRedirectButtonTextKey,
  redirectIsOnRight
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (isDirty) {
      confirmAlert({
        customUI: ConfirmBoxUi.bind(
          undefined,
          history,
          redirectPath,
          confirmHeaderTextKey,
          confirmCancelButtonTextKey,
          confirmRedirectButtonTextKey,
          redirectIsOnRight
        )
      });
    } else {
      history.push(redirectPath);
    }
  };

  return (
    <TjButton
      variant="secondary"
      type="button"
      disabled={submitting}
      onClick={handleClick}
      data-testid="cancelBtn">
      Cancel
    </TjButton>
  );
};

CancelButton.defaultProps = {
  confirmHeaderTextKey: 'general.cancelPopup.confirmHeaderText',
  confirmCancelButtonTextKey: 'general.cancelPopup.confirmCancelButtonText',
  confirmRedirectButtonTextKey: 'general.cancelPopup.confirmRedirectButtonText',
  redirectIsOnRight: true
};

CancelButton.propTypes = {
  isDirty: PropTypes.bool,
  submitting: PropTypes.bool,
  redirectPath: PropTypes.string,
  confirmHeaderTextKey: PropTypes.string,
  confirmCancelButtonTextKey: PropTypes.string,
  confirmRedirectButtonTextKey: PropTypes.string,
  redirectIsOnRight: PropTypes.bool
};

const ConfirmBoxUi = (
  history,
  redirectPath,
  confirmHeaderTextKey,
  confirmCancelButtonTextKey,
  confirmRedirectButtonTextKey,
  redirectIsOnRight,
  { onClose }
) => {
  return (
    <div
      className="card confirm-box-container"
      data-testid="confirmBoxContainer">
      <div className="card-body">
        <p data-testid="confirmMsg">{translate(confirmHeaderTextKey)}</p>
      </div>
      <div className="card-footer d-flex justify-content-center">
        <TjButton
          variant="secondary"
          type="button"
          data-testid="confirmBtnLeft"
          onClick={() => {
            onClose();
            !redirectIsOnRight && history.push(redirectPath);
          }}>
          {translate(
            redirectIsOnRight
              ? confirmCancelButtonTextKey
              : confirmRedirectButtonTextKey
          )}
        </TjButton>
        <TjButton
          type="button"
          data-testid="confirmBtnRight"
          onClick={() => {
            onClose();
            redirectIsOnRight && history.push(redirectPath);
          }}>
          {translate(
            redirectIsOnRight
              ? confirmRedirectButtonTextKey
              : confirmCancelButtonTextKey
          )}
        </TjButton>
      </div>
    </div>
  );
};

export default CancelButton;
