import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { TjButton, mutators } from '@talent-journey/tj-fe-commons';
import createDecorator from 'final-form-focus';
import { validateFormValues } from 'utils/formUtil';
import { ApplicationApplyOptionsField } from './ApplicationApplyOptionsField';
import { BasicField } from './BasicField';
import { SocialField } from './SocialField';
import { GeneralUiConfigField } from './GeneralUiConfigField';
import CancelButton from 'components/common/CancelButton';
import TenantFormValidationSchema from './tenantFormValidationSchema';
import { translate } from 'utils/i18nUtils';
import BranchConfiguration from './BranchConfiguration';

const focusOnError = createDecorator();

const TenantForm = ({
  handleSubmit,
  initialValues,
  tenantId,
  branchId,
  section
}) => {
  // const branchValidateSchema =
  //   section === 'ui'
  //     ? TenantFormBranchUIValidateSchema
  //     : TenantFormBranchApplicationOptionValidateSchema;
  const branchTenantHitBeforeSaveBtn =
    tenantId && !branchId
      ? translate('tenant.editTenantConfirmationInfo')
      : translate('tenant.editTenantBranchConfirmationInfo');
  return (
    <Form
      mutators={{
        ...mutators.fileMutators
      }}
      decorators={[focusOnError]}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize={true}
      validate={validateFormValues(TenantFormValidationSchema)}
      initialValues={initialValues}
      render={(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit} className="form-addcustomer">
            <Row className="flex-column">
              {!section ? (
                <Col>
                  <BasicField />
                </Col>
              ) : null}
              {!section ? (
                <Col>
                  <SocialField />
                </Col>
              ) : null}
              {!section || (branchId && section === 'ui') ? (
                <Col>
                  <GeneralUiConfigField branchId={branchId} />
                </Col>
              ) : null}
              {!section || (branchId && section === 'application-option') ? (
                <Col>
                  <ApplicationApplyOptionsField branchId={branchId} />
                </Col>
              ) : null}
              <Col>
                <BranchConfiguration
                  section={section}
                  branchId={branchId}
                  tenantId={tenantId}
                />
              </Col>
            </Row>
            <div className="floating-button-container-height-margin">
              <div className="form-group row mt-5">
                <p className="content-para">
                  {tenantId
                    ? branchTenantHitBeforeSaveBtn
                    : translate('tenant.createTenantConfirmationInfo')}
                </p>
              </div>
            </div>
            <div className="floating-button-container">
              <div className="shadow-div" />
              <div className="container">
                {tenantId ? (
                  <CancelButton
                    submitting={formProps.submitting}
                    isDirty={formProps.dirty}
                    redirectPath={
                      branchId && tenantId
                        ? `/tenant/${tenantId}/edit`
                        : '/tenant'
                    }
                    confirmHeaderTextKey="tenant.tenantCancelPopup.confirmHeaderText"
                    confirmCancelButtonTextKey="tenant.tenantCancelPopup.confirmEditCancelButtonText"
                    confirmRedirectButtonTextKey="tenant.tenantCancelPopup.confirmRedirectButtonText"
                  />
                ) : (
                  <CancelButton
                    submitting={formProps.submitting}
                    isDirty={formProps.dirty}
                    redirectPath="/tenant"
                    confirmHeaderTextKey="tenant.tenantCancelPopup.confirmHeaderText"
                    confirmCancelButtonTextKey="tenant.tenantCancelPopup.confirmAddCancelButtonText"
                    confirmRedirectButtonTextKey="tenant.tenantCancelPopup.confirmRedirectButtonText"
                  />
                )}
                <TjButton
                  disabled={formProps.submitting}
                  type="submit"
                  data-testid="submitBtn">
                  {tenantId ? 'Save' : 'Create'}
                </TjButton>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

/* eslint-disable */

TenantForm.propTypes = {
  tenantId: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  section: PropTypes.string,
  branchId: PropTypes.string,
  initialValues: PropTypes.shape({
    tenant_configuration: PropTypes.shape({
      application_options: PropTypes.object,
      styleInput: PropTypes.object,
      ui: PropTypes.object,
      branches: PropTypes.arrayOf(
        PropTypes.shape({
          application_options: PropTypes.object,
          styleInput: PropTypes.object,
          ui: PropTypes.object,
          branch_id: PropTypes.string,
          enable_specific_ui: PropTypes.bool,
          enable_application_options: PropTypes.bool
        })
      ),
      third_party_credentials: PropTypes.shape({
        linkedIn: PropTypes.shape({
          clientId: PropTypes.string,
          clientSecret: PropTypes.string
        }),
        xing: PropTypes.shape({
          consumerKey: PropTypes.shape({
            consumerKey: PropTypes.string,
            consumerSecret: PropTypes.string
          })
        })
      })
    })
  })
};
/* eslint-enable */

export default TenantForm;
