import React, { useContext, useState } from 'react';
import {
  SvgIcons,
  TjDropDownList,
  TjNotificationContext
} from '@talent-journey/tj-fe-commons';
import PropTypes from 'prop-types';
import { clearTenantJobCache } from 'api/customer/customerApi';
import { translate } from 'utils/i18nUtils';
import OuterLayerTrigger from 'components/common/OuterLayerTrigger';
import { StatusCodes } from 'http-status-codes';

const TenantAction = ({ tenantId }) => {
  const [show, setShow] = useState(false);

  const { showNotification } = useContext(TjNotificationContext);
  const options = translate('tenantActions.options');

  const handelOptionClick = (event) => {
    if (options[0] === event) {
      // Clear tenant Job Cache
      clearTenantJobCache(tenantId)
        .then(() => {
          showNotification(
            translate('tenantActions.clearTenantJobCache.messages.ok')
          );
        })
        .catch((e) => {
          const status = e.response.status;
          let message = '';
          if (status === StatusCodes.FORBIDDEN) {
            message = translate('forbidden');
          } else if (status === StatusCodes.NOT_FOUND) {
            message = translate(
              'tenantActions.clearTenantJobCache.messages.notFound'
            );
          } else {
            message = translate(
              'tenantActions.clearTenantJobCache.messages.serverError'
            );
          }

          showNotification(message, { type: 'error' });
        });
    }

    setShow(false);
  };

  return (
    <OuterLayerTrigger
      className={`tenant-action ${show ? 'active' : ''}`}
      data-testid="tenant-action"
      onClickOutside={() => setShow(false)}>
      <button
        type="button"
        data-testid="dropdown-btn"
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
        className="round-btn">
        <SvgIcons.ThreeDotsInHorizontalLine />
      </button>
      {show ? (
        <div className="option-container">
          <TjDropDownList onClick={handelOptionClick} options={options} />
        </div>
      ) : null}
    </OuterLayerTrigger>
  );
};

TenantAction.propTypes = {
  tenantId: PropTypes.string.isRequired
};

export default TenantAction;
