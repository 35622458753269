import { put, takeLeading, call } from '@redux-saga/core/effects';
import { createTodo } from 'api/todoApi';
import {
  ADD_TODO_ITEM,
  ADD_TODO_SAGA,
  TODO_REQUEST_STATUS,
  TODO_REQUEST_ERROR
} from 'store/types/todoTypes';

export function* addTodo({ payload: { todo } }) {
  yield put({ type: TODO_REQUEST_STATUS, payload: { busy: true } });

  try {
    yield call(createTodo, todo);
    yield put({ type: ADD_TODO_ITEM, payload: { todo } });
  } catch (error) {
    yield put({ type: TODO_REQUEST_ERROR, payload: { error: error.message } });
  }
}

export function* watchAddTodoAction() {
  yield takeLeading(ADD_TODO_SAGA, addTodo);
}
