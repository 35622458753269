import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { ExchangeOptionField } from '../../reactFinalForm/ExchangeOptionField';
import { TjCheckbox, Label } from '@talent-journey/tj-fe-commons';

export const ApplicationApplyOptionsField = () => {
  const prefix = 'tenant_configuration.ui.application_options';

  return (
    <>
      <div>
        <h4 className="section-title">Application Options:</h4>
      </div>
      <Card className="tenant-card application-apply-option-card">
        <Card.Body>
          <Row>
            <Col>
              <p>
                Please select the options you would like to present the
                application and sort them as desired. They will be presented to
                the application in the order:
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <strong>Select Options</strong>
              </p>
              <p>
                The application will be able to select any of these ways to
                apply:
              </p>
            </Col>
            <Col>
              <p>
                <strong>Available Options</strong>
              </p>
              <p>
                Move then to left section to display those options in the
                application:
              </p>
            </Col>
          </Row>

          <ExchangeOptionField
            name={`${prefix}.allowed_options`}
            options={[
              { label: 'Apply with CV', value: 'cv' },
              { label: 'Apply manually', value: 'manual' },
              { label: 'Apply with Linkedin', value: 'linkedIn' },
              { label: 'Apply with Xing', value: 'xing' },
              { label: 'Apply with Video', value: 'video' }
            ]}
          />
          <p>
            <strong>Job specific application options</strong>
          </p>
          <p>
            If this option is activated, the customer will be able to select the
            application option that they want every time they create a job. That
            section will then overwrite the configuration above.
          </p>
          <div className="checkbox-wrapper">
            <TjCheckbox
              name={`${prefix}.allow_overwrite_from_bm_specific_configuration`}
              id="allow_overwrite_from_bm_specific_configuration"
            />
            <Label
              htmlFor="allow_overwrite_from_bm_specific_configuration"
              className="label"
              label={
                <p>
                  Specific job configuration for the application options has
                  been activated in BM. This means that any recruiter creating a
                  new job can decide to alter the options that are configured in
                  the section above. If you with to change that,
                  <strong> please configure in the BM.</strong>
                </p>
              }
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
