import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import { fileConfig } from '@talent-journey/tj-fe-commons';
import axiosInstance from 'config/axiosConfig';

fileConfig.merge({
  httpRequest: axiosInstance
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
