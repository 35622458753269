import { StatusCodes } from 'http-status-codes';
import { translate } from 'utils/i18nUtils';

/**
 *
 * @param {string} status
 * @param {string} data
 * @param {string} moduleName - Name of the module who invokes the function
 * so it can match with the corresponding translation object.
 *
 */
export const handleApiErrors = (error, moduleName) => {
  const { status, data } = error ? error : {};
  return {
    status,
    getMessage: () => {
      if (StatusCodes[status]) {
        if (
          translate(`${moduleName}`) &&
          translate(`${moduleName}.${[StatusCodes[status]]}`)
        ) {
          return translate(`${moduleName}.${[StatusCodes[status]]}`);
        } else {
          return translate('unexpected');
        }
      } else {
        return translate('unknown');
      }
    },
    data
  };
};

export const serverSideMessageCompile = (message) => {
  return message;
};
