/* eslint-disable camelcase */

import { get } from 'lodash';
import { cssTranspiler } from 'utils/cssTranspilerUtil';
import { hexAToRGBA } from 'utils';

const getCssString = (styleInput) => {
  const primary_button_bg_color = getBtnBackGroundColor(
    styleInput,
    'primary_button_bg_color'
  );
  const primary_button_hover_bg_color = getBtnBackGroundColor(
    styleInput,
    'primary_button_hover_bg_color'
  );

  const image_overlay = getImageOverlayGradient(styleInput, 'image_overlay');
  const bg_gradient = getImageOverlayGradient(
    styleInput,
    'image_overlay',
    '45deg',
    1
  );

  const checkbox_radio_bg = getGradientValueInHEXAColorCode(
    styleInput,
    '45deg',
    'image_overlay.gradient.0',
    '0%',
    'image_overlay.gradient.1'
  );

  const image_overlay_first_color = get(styleInput, 'image_overlay.gradient.0');
  const conic_gradient_bg_color = getConicGradient(styleInput, 'image_overlay');

  const timeline_left_opacity_mask = `linear-gradient(to right, ${
    styleInput?.application_bg_color || '#f7f7f8'
  }, rgba(255, 255, 255, 0))`;

  const timeline_right_opacity_mask = `linear-gradient(to right, rgba(255, 255, 255, 0), ${
    styleInput?.application_bg_color || '#f7f7f8'
  })`;

  const cssVars = {
    ...styleInput,
    primary_button_bg_color,
    primary_button_hover_bg_color,
    image_overlay,
    bg_gradient,
    checkbox_radio_bg,
    image_overlay_first_color,
    conic_gradient_bg_color,
    timeline_left_opacity_mask,
    timeline_right_opacity_mask
  };

  return cssTranspiler(cssVars);
};

export function getBtnBackGroundColor(styleInput, name) {
  const btnVariant = get(styleInput, `${name}.variant`);
  let bgColor = '';
  if (btnVariant === 'plain') {
    bgColor = get(styleInput, `${name}.color`);
  }

  if (btnVariant === 'gradient') {
    bgColor = getGradientValueInHEXAColorCode(
      styleInput,
      '45deg',
      `${name}.gradient.0`,
      '0%',
      `${name}.gradient.1`,
      '100%'
    );
  }

  return bgColor;
}

function getGradientValueInHEXAColorCode(
  styleInput,
  direction = '45deg',
  firstColorValueKey,
  firstColorPercentage = '0%',
  secondColorValueKey,
  secondColorPercentage = '100%'
) {
  return `linear-gradient(${direction},${get(
    styleInput,
    firstColorValueKey
  )} ${firstColorPercentage}, ${get(
    styleInput,
    secondColorValueKey
  )} ${secondColorPercentage})`;
}

function getConicGradient(styleInput, name) {
  const image_overlay_color_1 = get(styleInput, `${name}.gradient.0`);
  const image_overlay_color_2 = get(styleInput, `${name}.gradient.1`);

  return `conic-gradient(${image_overlay_color_1} 0deg, ${image_overlay_color_2} 180deg, transparent 270deg);`;
}

export function getImageOverlayGradient(
  styleInput,
  name,
  degree = '0deg',
  alpha = 0.3
) {
  let image_overlay = '';
  const image_overlay_color_1 = get(styleInput, `${name}.gradient.0`);
  const image_overlay_color_2 = get(styleInput, `${name}.gradient.1`);
  if (image_overlay_color_1 && image_overlay_color_2) {
    image_overlay = `linear-gradient(${degree}, ${hexAToRGBA(
      image_overlay_color_1,
      alpha
    )}, ${hexAToRGBA(image_overlay_color_2, alpha)})`;
  }

  return image_overlay;
}

export default getCssString;
