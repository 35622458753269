import { v4 as uuidv4 } from 'uuid';

/**
 * To Inject the css string to into <head>
 * @param {string} css
 * @param {string} id
 */
export function injectCss(css, id) {
  const head = document.getElementsByTagName('head')[0];
  const styleElement = document.createElement('style');
  styleElement.setAttribute('type', 'text/css');
  if (id) {
    styleElement.id = id;
  }

  if (styleElement.styleSheet) {
    // IE
    styleElement.styleSheet.cssText = css;
  } else {
    // rest browser
    styleElement.appendChild(document.createTextNode(css));
  }

  head.appendChild(styleElement);
  return styleElement;
}

/**
 * To Convert the HexA Value in RGBA
 *
 * @param {string} hex color hexa value like: #f12f12
 * @param {number} alpha color opacity
 *
 * @returns {string} RGBA Value
 */
export const hexAToRGBA = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export function guidGenerator() {
  return uuidv4();
}
