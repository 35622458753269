import { useHistory, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Power } from 'react-bootstrap-icons';
import HaufeLogo from 'assets/images/list.svg';
import { deleteCookie } from '../../utils/common.util';

function Header() {
  const history = useHistory();

  const uponLogout = () => {
    deleteCookie('accessToken');
    history.push('/login');
  };

  return (
    <div className="c-nav-container c-newnav">
      <Container fluid className="headerComponent">
        <Row>
          <Col md={2} xs={6} className="c-logocontainer">
            <Link to="/tenant">
              <img src={HaufeLogo} alt="Haufe Umantis logo" />
            </Link>
          </Col>
          <Col>
            <Power
              onClick={() => {
                uponLogout();
              }}
              color="#ED1E79"
              size={40}
              className="logOut mt-2"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
